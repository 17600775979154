import React, { useEffect, useState } from "react";
import SponsoredService from "../../services/sponsored";
import decodeToken from "../../utils/decodeToken";
import SponseredKidCard from "./components/Card";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const SponsoredKids = () => {
  const { user_id, super_admin_id } = decodeToken();
  const [sponsoredKids, setSponsoredKids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    getAllSponsoredKids();
  }, []);

  // const getAllSponsoredKids = async () => {
  //   try {
  //     const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);
  //     // setSponsoredKids(
  //     //   data.data.filter((k) => !k.to_be_sponsor).map((d) => [d["kid_id"], d])
  //     // ).values();
  //     setSponsoredKids([
  //       ...new Map(data.data.map((d) => [d["kid_id"], d])).values(),
  //     ]);
  //     setLoading(false);
  //   } catch (err) {
  //     setSponsoredKids([]);
  //     console.log(err);
  //   }
  // };
  // const getAllSponsoredKids = async () => {
  //   try {
  //     const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);
  //     setSponsoredKids(data.data);
  //     setSponsoredKids(data.data.filter((k) => !k.to_be_sponsor));
  //     setLoading(false);
  //   } catch (err) {
  //     setSponsoredKids([]);
  //     console.log(err);
  //   }
  // };
  // const getAllSponsoredKids = async () => {
  //   try {
  //     const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);
  //     console.log("SSSSSSSSS", data);
  //     const uniqueKids = [
  //       ...new Map(data.data.map((d) => [d["kid_id"], d])).values(),
  //     ];

  //     setSponsoredKids(uniqueKids);
  //     setSponsoredKids(uniqueKids.filter((k) => !k.to_be_sponsor));
  //     setLoading(false);
  //   } catch (err) {
  //     setSponsoredKids([]);
  //     console.log(err);
  //   }
  // };
  // const getAllSponsoredKids = async () => {
  //   try {
  //     const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);

  //     const sortedKids = data.data.sort(
  //       (a, b) => new Date(b.mentored_on_date) - new Date(a.mentored_on_date)
  //     );

  //     const uniqueKids = Object.values(
  //       sortedKids.reduce((acc, curr) => {
  //         if (!acc[curr.kid_id]) {
  //           acc[curr.kid_id] = curr; // Keep the most recent record for each kid_id
  //         }
  //         return acc;
  //       }, {})
  //     );

  //     setSponsoredKids(uniqueKids.filter((k) => !k.to_be_sponsor));
  //     setLoading(false); // Finish loading state
  //   } catch (err) {
  //     setSponsoredKids([]); // Reset state on error
  //     setLoading(false); // Reset loading state
  //     console.log(err);
  //   }
  // };
  const getAllSponsoredKids = async () => {
    try {
      const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);

      // Sort by mentored_on_date in descending order (latest first) and keep only the latest record for each kid_id
      const uniqueKids = Object.values(
        data.data
          .sort(
            (a, b) =>
              new Date(b.mentored_on_date) - new Date(a.mentored_on_date)
          ) // Sort by date
          .reduce((acc, curr) => {
            // Only keep the most recent record for each kid_id
            if (!acc[curr.kid_id]) {
              acc[curr.kid_id] = curr;
            }
            return acc;
          }, {})
      );
      setSponsoredKids(uniqueKids.filter((k) => !k.to_be_sponsor));

      setLoading(false);
    } catch (err) {
      setSponsoredKids([]);
      setLoading(false); // Make sure to reset loading state on error
      console.log(err);
    }
  };
  const onViewProfile = (id) => {
    navigate(`/kid/${id}?isSponsored=true`);
  };

  const updateKidToBeSponsor = async (kid_id, kid_name) => {
    let to_be_sponsor = true;
    let is_sponsored = false;
    try {
      const { data } = await SponsoredService.updateKidToBeSponsor({
        kid_id,
        to_be_sponsor,
        is_sponsored,
      });
      toast.success(`${kid_name} To Be Sponsor successfully`);
      navigate(`/`);
    } catch (err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="container my-3">
      <div className="card-body p-4">
        {/* <h5>
            <b>Sponsored Kids</b>
          </h5> */}

        <div className="row mt-2">
          {loading ? (
            <Loader />
          ) : sponsoredKids?.length > 0 ? (
            sponsoredKids?.map((kid) => (
              <div className="col-sm-3">
                <SponseredKidCard
                  user_id={user_id}
                  super_admin_id={super_admin_id}
                  onViewProfile={onViewProfile}
                  updateKidToBeSponsor={updateKidToBeSponsor}
                  data={kid}
                />
              </div>
            ))
          ) : (
            <h5 className="alert alert-danger text-center">
              No Sponsored Kid Found
            </h5>
          )}
        </div>
      </div>
    </div>
  );
};
export default SponsoredKids;
